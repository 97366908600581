import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AutoFocusModule } from 'primeng/autofocus';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@Component({
  selector: 'rpp-modal-confirmation',
  standalone: true,
  imports: [CommonModule, ConfirmDialogModule, AutoFocusModule],
  templateUrl: './modal-confirmation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RppModalConfirmationComponent {}
