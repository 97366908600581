<div class="layout-wrapper" [ngClass]="containerClass">
  <rpp-topbar />
  <rpp-layout-sidebar />
  <div class="layout-main-container">
    <div class="layout-main">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="layout-mask"></div>
  <rpp-modal-confirmation />
</div>
